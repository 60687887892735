<template>
  <div ref="body">
    <custom-header active="exh" :subActive="current"></custom-header>

    <div class="content-container relative ">
      <div class="box1 relative">
        <h1>展商事务公关部</h1>
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/service/1.png" class="img1 block" />
      </div>
      <div class="box2">
        <div class="relative">
          <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/service/2.png" class="img2 block" />
          <!-- 优选供应商甄选服务PDF下载 -->
          <!-- <a href="supplier.pdf" download="优选供应商.pdf" target="_blank" class="absolute block link1"></a> -->
          <!-- <a class="absolute block link1" @click="downloadPDF1"></a> -->
        </div>
        <div class="relative">
          <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/service/3.png" class="img3 block" />
        </div>
        <div class="relative">
          <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/service/4.png" class="img4 block" />
        </div>
      </div>
      <div class="box3">
        <div class="relative">
          <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/service/5.png" class="img5 block" />
          <!-- 深航广告页 -->
          <a class="absolute block link2" @click="showImg('shenhangImg')"></a>
        </div>
        <div class="relative">
          <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/service/6.png" class="img6 block" />
          <!-- 一站式物流服务: 招华物流广告页 -->
          <a class="absolute block link3" @click="showImg('zhaohuaImg')"></a>
          <!-- 搭建商管理服务: 展位搭建情况页 -->
          <!-- <a href="https://www.szcreativeweek.com/mfgbv" target="_blank" class="absolute block link4"></a> -->
          <a class="absolute block link4" @click="showDetail"></a>
          
          <!-- 优选供应商 -->
          <!-- <a href="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/supplier.pdf" target="_blank" class="absolute block link5"></a> -->
          <a class="absolute block link5" @click="downloadPDF1"></a>
        </div>
      </div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
    
    <van-overlay :show="isShowImg" z-index="10"  @click="closeImg">
      <div class="" @click.stop>
        <div class="service-img-box bg-white scrollBar">
          <div class="">
            <img :src="url" class="service-img" />
          </div>
        </div>
      </div>
    </van-overlay>
    
    <van-overlay :show="isShowDetail" z-index="10"  @click="closeDetail">
      <div class="" @click.stop>
        <div class="service-img-box bg-white scrollBar">
          <div><h1 class="text-bold">40届展位搭建情况查询</h1></div>
          <div class="desc">请点击
            <a href="http://zhan.zzxes.com.cn/special/39szcw" target="_blank" rel="noopener noreferrer" class="alink">此处</a>
            至主场服务智奥会展（深圳）有限公司或请点击
            <a class="alink" @click="downloadPDF2">此处</a>
            至主场服务深圳市卡司通展览股份有限公司获取40届展位搭建情况，此情况仅做为参考信息，且仅对40届深圳国际家具设计展展览期间使用之搭建公司展位搭建的情况说明，主办方机构不予推荐任何搭建公司，如参展商使用造成的任何问题，主办机构不予负责。
          </div>
        </div>
      </div>
    </van-overlay>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon, Overlay } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Overlay.name]: Overlay,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'service',
      isShowDetail: false,
      isShowImg: false,
      url: '',
      shenhangImg: 'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/shenhang.jpg',
      zhaohuaImg: 'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/zhaohua.jpg',
    }
  },
  created() {

  },
  methods: {
    showImg(key) {
      this.url = this[key];
      this.isShowImg = true;
    },
    closeImg() {
      this.url = ''
      this.isShowImg = false;
    },
    showDetail() {
      this.isShowDetail = true;
    },
    closeDetail() {
      this.isShowDetail = false;
    },
    downloadPDF1() {
      let a = document.createElement("a");
      a.href = 'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/supplier.pdf';
      a.download = '优选供应商.pdf';
      a.target = '_blank';
      a.click()
    },
    downloadPDF2() {
      let a = document.createElement("a");
      a.href = 'https://expo.kastone.com.cn/document/20240410_100000.pdf';
      a.download = '【2023深圳展】搭建违规信息统计表.pdf';
      a.target = '_blank';
      a.click()
    },
  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container-en {
    width: 375px;
    margin: 0 auto 20px;
    
    h1 {
      font-size: 17px;
      margin: 15px 0 30px;
      text-align: center;
    }
    
    .section {
      width: 100%;
      padding: 0 20px;
    }
    
    .flex-sub {
      margin-bottom: 30px;
      
      h2 {
        font-size: 13px;
        margin-bottom: 5px;
        font-family: 'SourceHanSansCN-Bold';
      }
      
      .intro {
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
    
    .row {
      margin-bottom: 30px;
      
      h2 {
        font-size: 13px;
        margin-bottom: 5px;
        font-family: 'SourceHanSansCN-Bold';
      }
      .intro {
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
  }
  
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .box1 {
      width: 375px;
      margin: 0 auto;
      
      h1 {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        font-size: 17px;
        margin-bottom: 10px;
        font-family: 'SourceHanSansCN-Bold';
      }
      
      .img1 {
        width: 100%;
        height: auto;
      }
    }
  
    .box2 {
      width: 370px;
      margin: 0 auto 10px;
      height: 450px;
      
      .img2 {
        position: absolute;
        top: -33px;
        left: -9px;
        z-index: 1;
        width: 320px;
        height: auto;
        margin-right: 10px;
      }
      .img3 {
        position: absolute;
        top: 140px;
        left: -2px;
        z-index: 1;
        width: 370px;
        height: auto;
      }
      .img4 {
        position: absolute;
        top: 330px;
        left: 5px;
        z-index: 1;
        width: 370px;
        height: auto;
      }
      
      .link1 {
        z-index: 2;
        top: 15px;
        left: 15px;
        width: 320px;
        height: 95px;
      }
    }
    .box3 {
      width: 375px;
      margin: 0 auto;
      height: 850px;
      
      .img5 {
        position: absolute;
        top: 0;
        left: -40px;
        z-index: 1;
        width: 375px;
        height: auto;
      }
      .img6 {
        position: absolute;
        top: 430px;
        left: -32px;
        z-index: 1;
        width: 375px;
        height: auto;
      }
      
      .link2 {
        z-index: 2;
        top: 230px;
        left: 20px;
        width: 300px;
        height: 60px;
      }
      .link3 {
        z-index: 3;
        top: 445px;
        left: 20px;
        width: 300px;
        height: 65px;
      }
      .link4 {
        z-index: 4;
        top: 545px;
        left: 20px;
        width: 300px;
        height: 65px;
      }
      .link5 {
        z-index: 4;
        top: 768px;
        left: 20px;
        width: 300px;
        height: 65px;
        // background-color: rgba(0, 0, 0, .2);
      }
    }
  
  }

  .service-img-box {
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 85vh;
    width: 360px;
    padding: 10px;
    border-radius: 4px;
    overflow: auto;
    
    .service-img {
      display: block;
      width: 90%;
      height: auto;
      margin: 10px auto;
      border-radius: 4px;
    }
    
    h1 {
      font-size: 20px;
      text-align: center;
      margin: 20px 0;
    }
    
    .desc {
      text-indent: 2em;
      font-size: 14px!important;
      line-height: 1.8em;
      padding: 20px 10px;
    }
    
    .alink {
      text-decoration: underline;
      color: #2e5bff;
    }
    
  }
}

// PC
@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  
  .content-container-en {
    width: 280px;
    margin: 10px auto 20px;
    
    h1 {
      font-size: 7px;
      margin: 20px 0;
      text-align: center;
    }
    
    .section {
      display: flex;
      gap: 16px;
      width: 100%;
      margin: 0 auto 20px;
    }
    .column-1 {
      width: 132px;
      padding: 0 20px;
    }
    
    h2 {
      font-size: 3.3px;
      margin-bottom: 5px;
      font-family: 'SourceHanSansCN-Bold';
    }
    
    .intro {
      font-size: 3.2px;
      margin-bottom: 2px;
    }
    
    .row {
      margin-bottom: 7px;
      
      h2 {
        font-size: 3px;
        margin-bottom: 2px;
        font-family: 'SourceHanSansCN-Bold';
      }
    }
  }
  .content-container {
    width: 280px;
    margin: 0 auto 20px;
    
    .box1 {
      width: 170px;
      margin: 0 auto;
      
      h1 {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        font-size: 7px;
        margin-bottom: 10px;
        font-family: 'SourceHanSansCN-Bold';
      }
      
      .img1 {
        width: 100%;
        height: auto;
      }
    }
  
    .box2 {
      display: flex;
      justify-content: space-between;
      width: 250px;
      margin: 0 auto 10px;
      
      .img2 {
        width: 80px;
        height: auto;
      }
      .img3 {
        width: 80px;
        height: auto;
        margin-top: 8px;
      }
      .img4 {
        width: 80px;
        height: auto;
        margin-top: 7px;
      }
      
      .link1 {
        z-index: 1;
        top: 25%;
        right: 6%;
        bottom: 28%;
        left: 7%;
      }
    }
    .box3 {
      display: flex;
      justify-content: space-between;
      width: 240px;
      margin: 0 auto;
      
      .img5 {
        width: 120px;
        height: auto;
      }
      .img6 {
        width: 120px;
        height: auto;
      }
      
      .link2 {
        z-index: 2;
        top: 54%;
        right: 20%;
        bottom: 32%;
        left: 13%;
      }
      .link3 {
        z-index: 3;
        top: 3%;
        right: 20%;
        bottom: 80%;
        left: 13%;
      }
      .link4 {
        z-index: 4;
        top: 27%;
        right: 15%;
        bottom: 58%;
        left: 13%;
      }
      .link5 {
        z-index: 4;
        top: 78%;
        right: 10%;
        bottom: 5%;
        left: 13%;
        // background-color: rgba(0, 0, 0, .2);
      }
    }
    
    .link1,
    .link2,
    .link3,
    .link4,
    .link5 {
      border-radius: 4Px;
      
      &:hover {
        box-shadow: 0 2Px 10Px rgba(0, 0, 0, .2);
      }
    }
  
  }
  
  .service-img-box {
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 149px;
    // max-width: 800Px;
    // min-width: 500Px;
    max-height: 80vh;
    padding: 5px;
    border-radius: 2px;
    overflow: auto;
    
    .service-img {
      display: block;
      width: 100%;
      height: auto;
      margin: 2px auto;
      border-radius: 1px;
    }
    
    
    h1 {
      font-size: 6px;
      text-align: center;
      margin: 10px 0 3px;
    }
    
    .desc {
      text-indent: 2em;
      font-size: 3.4px!important;
      line-height: 1.8em;
      padding: 10px 5px;
    }
    
    .alink {
      text-decoration: underline;
      color: #2e5bff;
    }
    
  }
  

}

  
</style>